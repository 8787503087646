import {
  Box,
  Button,
  Card,
  CardBody,
  Form,
  FormField,
  Heading,
  TextInput,
} from "grommet";
import React, { useState } from "react";
import { isAuthenticated, register } from "../../misc/Authentication";

/**
 * This component will render the register form
 * @returns 
 */
const RegisterForm = () => {
  const [value, setValue] = useState({});
  isAuthenticated().then((v) => (v ? (window.location = "/") : null));
  return (
    <Box>
      <Card alignSelf="center" align="center" margin="medium" pad="large">
        <CardBody>
          <Heading alignSelf="center" margin="xxsmall">
            James
          </Heading>
          <Heading level={2} alignSelf="center">
            Registrierung
          </Heading>

          <Form
            value={value}
            onChange={(nextValue) => {
              setValue(nextValue);
            }}
            onReset={() => setValue({})}
            onSubmit={({ value }) => register(value)}
            validate="blur"
          >
            <FormField name="email" label="E-Mail" htmlFor="email-input-id" required>
              <TextInput
                name="email"
                id="email-input-id"
                type="email"
              ></TextInput>
            </FormField>
            <FormField
            required
              name="password"
              label="Passwort"
              htmlFor="password-input-id"
            >
              <TextInput
                type="password"
                id="password-input-id"
                name="password"
              ></TextInput>
            </FormField>
            <FormField
            required
              name="passwordV"
              label="Passwort Bestätigen"
              htmlFor="password-inputV-id"
                          validate={(pw, v) => {
                              if (pw !== v.password) return "Passwörter stimmen nicht überein";
              }}
            >
              <TextInput
                type="password"
                id="password-inputV-id"
                name="passwordV"
              ></TextInput>
            </FormField>
            <Box direction="row" gap="medium">
              <Button type="submit" primary label="Registrieren"></Button>
              <Button
                type="button"
                label="Anmelden"
                onClick={(event) => (window.location = "/login")}
              ></Button>
            </Box>
          </Form>
        </CardBody>
      </Card>
    </Box>
  );
};
export default RegisterForm;
