import {
    Button,
    Card,
    CardBody, CardFooter, Layer, Text
} from "grommet";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SkillsApi } from "../../../misc/Api";
const SkillDetailsPopup = () => {

    const navigate = useNavigate();
    const { guid, skill } = useParams();
    const [fullSkill, setFullSkill] = useState();

    useEffect(() => {
        requestSkill();
    });

    const requestSkill = () => {
        SkillsApi.find({
            query: {
                _id: skill,
            },
        }).then((result) => setFullSkill(result.data[0]));
    };

    if (!fullSkill) {
        return <div className="App">Loading...</div>;
    }

    return (
        <Layer>
            <Card round="small" width="large" alignSelf="center" background="white">
                <CardBody pad="medium" justify="center">
                    <Text weight="bold">Id:</Text>
                    <Text>{fullSkill._id}</Text>
                    <Text weight="bold">Name:</Text>
                    <Text>{fullSkill.name}</Text>
                    <Text weight="bold">Group:</Text>
                    <Text>{fullSkill.group}</Text>
                    <Text weight="bold">Data:</Text>
                    <Text>{fullSkill.data}</Text>
                    <Text weight="bold">ExamNr:</Text>
                    <Text>{fullSkill.examnr}</Text>
                    <Text weight="bold">Guid</Text>
                    <Text>{fullSkill.guid}</Text>
                    <Text weight="bold">Skill:</Text>
                    <Text>{fullSkill.skill}</Text>
                    <Text weight="bold">Time:</Text>
                    <Text>{fullSkill.time}</Text>
                </CardBody>
                <CardFooter direction="column" alignContent="center" pad="small">
                    <Button label="Schliessen" onClick={() => navigate(-1)} ></Button>
                </CardFooter>
            </Card>
        </Layer>
    );
}
export default SkillDetailsPopup;