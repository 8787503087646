import React from "react";
import RegisterForm from "../../atoms/RegisterForm";
/**
 * This component renders all components for the RegisterPage
 * @returns 
 */
const RegisterPage = () => {
 return <RegisterForm/>
};

export default RegisterPage;
