import { Heading, Table, TableBody, TableCell, TableHeader, TableRow, Text } from "grommet";
import React from "react";
import PublicSkillCard from "../atoms/Skills/PublicSkillCard";
import SkillCard from "../atoms/Skills/SkillCard";
/**
 * This component will render a table with all skills from the database and will highlight the finished ones
 * @param {[]} tasks - This array contains the tasks which should be rendered
 * @param {[]} skills - This array contains the finished skills
 * @returns 
 */
const SkillsTable = ({ tasks, skills, isPublic=false }) => {

    if(!tasks || !skills){
      return <p>Loading..</p>
    }

    const SkillCell = (skill) => {
      if(isPublic){
        return <PublicSkillCard skills={skills} skill={skill}></PublicSkillCard>
      }
      return <SkillCard skills={skills} skill={skill}></SkillCard>
    }

    return (
      <Table style={{ height: "100%" }} margin={{top: 'xxsmall' }}>
        <TableHeader>
          <TableRow>
            <TableCell scope="col" />
            <TableCell scope="col">Beschreibung</TableCell>
            <TableCell scope="col">Gewichtung</TableCell>
            <TableCell scope="col">Windows</TableCell>
            <TableCell scope="col" />
            <TableCell scope="col" />
            <TableCell scope="col">Linux</TableCell>
            <TableCell scope="col" />
          </TableRow>
        </TableHeader>
        <TableBody>
          {tasks.map((data, i) => (
            <TableRow style={{ borderBottom: "1px solid #CCC" }} key={i}>
              <TableCell>
                <Heading>{data.category}</Heading>
              </TableCell>
              <TableCell>
                <Text>{data.description}</Text>
              </TableCell>
              <TableCell>
                <Text>{data.weight}%</Text>
              </TableCell>
              {data.skills.map((skill) => (
                <TableCell pad={{ horizontal: 'xsmall', vertical: 'small' }} key={skill.id}>
                  {SkillCell(skill)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
}

export default SkillsTable;