import {
  Box,
  Button,
} from "grommet";
import * as Icons from "grommet-icons";

import React, { useEffect } from "react";
/**
 * This component will render a table with all skills from the database and will highlight the finished ones
 * @param {[]} tasks - This array contains the tasks which should be rendered
 * @param {[]} skills - This array contains the finished skills
 * @returns 
 */
const PrintIcon = ({ teamId, orientation = 'portrait' }) => {
	
	const setPageSize = (cssPageSize) => {
		const style = document.createElement('style');
		style.innerHTML = `@page {size: ${cssPageSize} , transform: scale(.1);}`;
		style.id = 'page-orientation';
		document.head.appendChild(style);
	}


    // Set orientation of page being printed
    useEffect(() => {
        setPageSize(orientation);
        return () => {
            const child = document.getElementById('page-orientation');
            child.parentNode.removeChild(child);
        };
    }, [orientation]);

	var css = '@page { size: landscape; transform: scale(.43) }',
    head = document.head || document.getElementsByTagName('head')[0],
    style = document.createElement('style');

	style.type = 'text/css';
	style.media = 'print';

	if (style.styleSheet){
	  style.styleSheet.cssText = css;
	} else {
	  style.appendChild(document.createTextNode(css));
}

head.appendChild(style);



    return (
		
	<Box pad="xlarge">
        <Button icon={<Icons.Print/>} label="Click to Print" primary onClick={() => window.print()} />
	</Box>
		

    );
}

export default PrintIcon;