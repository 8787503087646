import { Button, Card, CardBody, CardFooter, Layer, Text, Box } from "grommet";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TasksApi, SkillsApi } from "../../../misc/Api";
const GivePointForm = () => {
  const { guid, skill } = useParams();
  const [maxPoints, setMaxPoints] = useState(2);
  const [fullSkill, setFullSkill] = useState([]);
  const [value, setValue] = useState(0);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    requestMaxpoints();
    requestSkill();
  }, []);

  const requestMaxpoints = () => {
    TasksApi.find({
      query: {
        "skills.id": skill,
        $select: ["skills.$"],
      },
    }).then((result) => {
      if (result.data[0].skills[0].points) {
        setMaxPoints(result.data[0].skills[0].points);
      }
    });
  };
  const requestSkill = () => {
    SkillsApi.find({
      query: {
        skill: skill,
        guid: guid,
      },
    }).then((result) => {
      setFullSkill(result.data[0]);
      console.log(result);
    });
  };
  const handlePointsSave = () => {
    SkillsApi.find({
      query: {
        skill: skill,
        guid: guid,
        $select: ["skill"],
      },
    }).then((result) => savePoints(result.data[0]));
  };

  const savePoints = (skill) => {
    SkillsApi.patch(skill._id, {
      points: value,
    });
    navigate("/apprentice/" + guid);
    window.location.reload();
  };

  return (
    <Layer>
      <Card round="small" width="xmedium" alignSelf="center" background="white">
        <Text alignSelf="center">Infos</Text>
        <CardBody pad="medium" justify="center">

          <Text weight="bold">Id:</Text>
          <Text>{fullSkill._id}</Text>
          <Text weight="bold">Name:</Text>
          <Text>{fullSkill.name}</Text>
          <Text weight="bold">Group:</Text>
          <Text>{fullSkill.group}</Text>
          <Text weight="bold">Data:</Text>
          <Text>{fullSkill.data}</Text>
          <Text weight="bold">ExamNr:</Text>
          <Text>{fullSkill.examnr}</Text>
          <Text weight="bold">Guid</Text>
          <Text>{fullSkill.guid}</Text>
          <Text weight="bold">Skill:</Text>
          <Text>{fullSkill.skill}</Text>
          <Text weight="bold">Time:</Text>
          <Text>{fullSkill.time}</Text>

        </CardBody>

        <CardFooter direction="column" alignContent="center" pad="small">
          <Text>Wievie Punkte möchten sie vergeben?</Text>
          <select
            style={{ width: "35%" }}
            value={value}
            onChange={handleChange}
          >
            <SelectPoints maxPoints={maxPoints}></SelectPoints>
          </select>
          <Button label="Bestätigen" onClick={handlePointsSave}></Button>
        </CardFooter>
      </Card>
    </Layer>
  );
};
const SelectPoints = ({ maxPoints }) => {
  const options = [];
  for (var i = 0; i <= maxPoints; i++) {
    const option = <option>{i}</option>;
    options.push(option);
  }
  return options;
};
export default GivePointForm;
