import feathers from "@feathersjs/client";
/**
 * In this file all the apis are initialsed
 */
const app = feathers();
app.configure(feathers.rest(process.env.REACT_APP_BACKEND_URL).fetch(window.fetch));
app.configure(feathers.authentication({storageKey: "auth"}))
export const SkillsApi = app.service("skills");
export const ApprenticesApi = app.service("apprentices");
export const TasksApi = app.service("tasks");
export const UsersApi = app.service("users");

export default app;
