import app, { UsersApi } from "./Api";
/**
 * Handles the login
 * @param {Object} value - The form data
 */
export const login = async (value) => {
  try {
    await app.reAuthenticate();
  } catch (err) {
    console.error(err);
    try {
      value["strategy"] = "local";
      await app.authenticate(value);
    } catch (er) {
      window.location = "/login";
    }
  } finally {
    window.location = "/";
  }
};

/**
 * Handles the registration
 * @param {Object} value - The form data
 */
export const register = async (value) => {
    await UsersApi.create(value);
    window.location = "/login";
} 

/**
 * Check wether user is authenticated or not
 * @returns 
 */
export const isAuthenticated = async () => {
  try {
    await app.reAuthenticate();
  } catch (err) {
    return false;
  }
  return true;
};
/**
 * Handles the logout
 */
export const logout = async () => {
    await app.logout();
    window.location = "/login" ;
}