import {
    Button,
    Card,
    CardBody, CardFooter, Layer, Text
} from "grommet";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SkillsApi } from "../../../misc/Api";
const SkillDeletePopup = () => {

    const navigate = useNavigate();
    const { guid, skill } = useParams();
    const [fullSkill, setFullSkill] = useState();

    useEffect(() => {
        requestSkill();
    });

    const requestSkill = () => {
        SkillsApi.find({
            query: {
                _id: skill,
            },
        }).then((result) => setFullSkill(result.data[0]));
    };

    if (!fullSkill) {
        return <div className="App">Loading...</div>;
    }
    const deleteSkill = () =>{
    
        SkillsApi.remove(skill).then((result) => {
            navigate(`/apprentice/${fullSkill.guid}`);
            window.location.reload();
        });
       
    }
    return (
        <Layer>
            <Card round="small" width="xmedium" alignSelf="center" background="white" align="center" pad="small">
                <Text>Sie sie sicher das sie diesen Skill löschen wollen?</Text>
                <CardFooter direction="row" alignContent="center" pad="small">
                    <Button label="Ja" onClick={() => deleteSkill()} ></Button>
                    <Button label="Abbrechen" onClick={() => navigate(-1)} ></Button>
                </CardFooter>
            </Card>
        </Layer>
    );
}
export default SkillDeletePopup;