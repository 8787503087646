import { Card, CardBody, CardFooter, CardHeader } from 'grommet';
import React from 'react';

const PublicSkillCard = ({ skills, skill }) => {

  if(!skill && !skills){
    return <p>Loading..</p>
  }

  var fullSkill;

  fullSkill = skills.find((tempSkill) => {
    return tempSkill.skill === skill.id;
  });

  const cardColor = () => {
    if (fullSkill) {
      return { background: '#FBF3CF', height: '100%' };
    }
    return { background: '#FFC8AF', height: '100%' };
  };

  return (
    <Card
      style={cardColor(skill.id)}
      pad={{ horizontal: 'xxsmall', vertical: 'xxsmall' }}
    >
      <CardHeader><b>{skill.id}</b></CardHeader>
      <CardBody>{skill.description}</CardBody>
      <CardFooter pad={{ horizontal: 'small' }}></CardFooter>
    </Card>
  );
};

export default PublicSkillCard;
