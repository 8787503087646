import React from "react";
import LoginForm from "../../atoms/LoginForm";


/**
 * This component renders all components for the LoginPage
 * @returns 
 */
const LoginPage = () => {
  return <LoginForm />;
};

export default LoginPage;
