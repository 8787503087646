import { Route } from 'react-router';
import AuthGuard from './AuthGuard';

const PrivateRoute = ({ children, ...rest }) => {
  return (
    <>
      <AuthGuard />
      <Route {...rest} render={{children}} />
    </>
  );
};

export default PrivateRoute;
