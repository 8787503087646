import { Button, Card, CardBody, CardFooter, CardHeader } from 'grommet';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Chip } from '../Chip';
import * as Icons from 'grommet-icons';
import { SkillsApi } from '../../../misc/Api';

const SkillCard = ({ skills, skill }) => {
  const navigate = useNavigate();
  let { guid } = useParams();

  if (!skill && !skills) {
    return <p>Loading..</p>
  }

  var fullSkill;

  fullSkill = skills.find((tempSkill) => {
    return tempSkill.skill === skill.id;
  });

  const editSkill = () => navigate(`givepointform/${fullSkill.skill}`);
  const detailsSkill = () => navigate(`details/${fullSkill._id}`);
  const deleteSkill = () => navigate(`deleteskill/${fullSkill._id}`);

  const addSkill = () => {
    SkillsApi.create(
      {
        guid: guid,
        skill: skill.id,
        time: Date.now()
      }).then((result) => navigate(`givepointform/${result.skill}`));
  };

  const cardColor = () => {
    if (fullSkill && fullSkill.points) {
      return { background: '#E1F5DE', height: '100%' };
    } else if (fullSkill) {
      return { background: '#FBF3CF', height: '100%' };
    }
    return { background: '#FFC8AF', height: '100%' };
  };

  const cardPoints = () => {
    if (fullSkill && fullSkill.points) {
      return <Chip round="full">{fullSkill.points}</Chip>
    }
    return <Chip round="full">0</Chip>
  }



  const cardButtons = () => {
    if (fullSkill) {
      return (
        <>
          <Button icon={<Icons.Edit />} hoverIndicator onClick={editSkill} />
          <Button
            icon={<Icons.Search />}
            hoverIndicator
            onClick={detailsSkill}
          />
          <Button
            icon={<Icons.Trash />}
            hoverIndicator
            onClick={deleteSkill}
          />
        </>
      );
    }
    return <Button icon={<Icons.Add />} hoverIndicator onClick={addSkill} />;
  };

  return (
    <Card
      style={cardColor(skill.id)}
      pad={{ horizontal: 'xxsmall', vertical: 'xxsmall' }}
    >
      <CardHeader>
        <b>{skill.id}</b>
        {cardPoints()}
      </CardHeader>
      <CardBody>{skill.description}</CardBody>
      <CardFooter pad={{ horizontal: 'small' }}>
        {cardButtons()}
      </CardFooter>
    </Card>
  );
};

export default SkillCard;
