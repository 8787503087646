import { Box, Card, CardBody, CardFooter, Button } from "grommet";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Scanner from "react-webcam-qr-scanner";
import { parseAndSaveData, parse } from "../../misc/ScannerHelper";

/**
 * This component will render the Scanner PopUp and handel the corresponding logic
 * @param {boolean} visible - Contains the visibility state of the Scanner
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setVisible - Contains the SET-Action for the visibility state
 * @returns 
 */
    const QrScanner = () => {

  const textInput = useRef(); 
  const navigate = useNavigate();
  const handleDecode = (result) => {
    console.log(result);
    if (result.data !== "") {
      //TODO Decode data and write to database
      parseAndSaveData(result.data);
      const data = parse(result.data);
      navigate("/pointform/"+data.guid+"/"+data.skill);
    }
  };

  const handleBCScanner = () => {
    textInput.current.focus();
  }

  const handleEnter = (event) => {
    if(event.key === 'Enter'){
     
      console.log(textInput.current.value);
      parseAndSaveData(textInput.current.value);
      //navigate("/pointform/"+encodeURIComponent(textInput.current.value));
      const data = parse(textInput.current.value);
      navigate("/pointform/"+data.guid+"/"+data.skill);
      textInput.current.value = ""; 
      
     
    }
  }

  return (
    <Box
      fill
      direction="row"
      justify="center"
    >
      <Card>
      <CardBody pad="xlarge">
      <Scanner onDecode={handleDecode} />
        </CardBody>
        <CardFooter pad="medium" justify="center">
         <Button primary label="Use Scanner" onClick={handleBCScanner} />
          <input type="text" ref={textInput} class="hidden-bc-input" onKeyPress={handleEnter}></input>
        </CardFooter>
      </Card>
    </Box>
  );
};

export default QrScanner;