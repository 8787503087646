import { isAuthenticated } from "../../misc/Authentication";
/**
 * Check if user is logged in if not he will be redirected to the login page
 * @returns {null}
 */
const AuthGuard = () => {
  isAuthenticated().then((v) => (v ? null : (window.location = "/login")));
  return null;
};

export default AuthGuard;
