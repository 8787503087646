import { Box, Button, Header, Heading, Main, Menu } from "grommet";
import { Logout } from "grommet-icons";
import { useState } from "react";
import { Route, Routes, BrowserRouter as Router} from "react-router-dom";
import HomePage from "./components/pages/HomePage/HomePage";
import LoginPage from "./components/pages/LoginPage/LoginPage";
import OverviewPage from "./components/pages/OverviewPage/OverviewPage";
import RegisterPage from "./components/pages/RegisterPage/RegisterPage";
import { isAuthenticated, logout } from "./misc/Authentication";
import QrScanner from "./components/atoms/Scanner";
import PointForm from "./components/atoms/Points/PointForm";
import GivePointForm from "./components/atoms/Points/GivePointForm";
import * as Icons from 'grommet-icons';
import SkillDetailsPopup from "./components/atoms/Skills/SkillDetailsPopup";
import SkillDeletePopup from "./components/atoms/Skills/SkillDeletePopup";
import PrivateRoute from "./components/atoms/PrivateRoute";
function App() {
  const [showLogout, setShowLogout] = useState(false);
  isAuthenticated().then((v) => setShowLogout(v));
  return (
    <Router>
      <Header>
        <Button icon={<Icons.Home />} onClick={() => window.location = "/"} hoverIndicator />
        <Heading level="3">James</Heading>
        <Box margin={{left:"auto"}}></Box>
        {showLogout ? (
          <Menu label="My Account" items={[{ label: 'Logout', icon:<Logout/>, onClick: ()=>logout() }]} />
        ) : null}
      </Header>
      <Main fill={true}>

          <Routes>
            <Route path="/login" element={<LoginPage/>} />
            <Route path="/register" element={<RegisterPage/>} />
            <PrivateRoute path="/" element={<HomePage/>}>
              <PrivateRoute path="/pointform/:guid/:skill" element={<PointForm/>} />
            </PrivateRoute>
            <PrivateRoute path="/apprentice/:guid" element={<OverviewPage />}>
              <PrivateRoute path="/givepointform/:skill" element={<GivePointForm/>} />
              <PrivateRoute path="/details/:skill" element={<SkillDetailsPopup/>} />
              <PrivateRoute path="/deleteskill/:skill" element={<SkillDeletePopup/>} />
            </PrivateRoute>
            <Route path="/status/:guid" element={<OverviewPage isPublic/>} />
            <PrivateRoute path="/scanner" element={<QrScanner/>} />
          </Routes>
        
      </Main>
      </Router>
  );
}

export default App;
