import { DataTable, Text } from "grommet";
import React from "react";
/**
 * This component will render a list of all apprentices. The list consists of the apprentices name and its guid. Once an entry is clicked the user will be redirected to the OverviewPage for this apprentice.
 * @param {{[]}} apprentices - An array which contains all the apprentices
 * @returns
 */
const ApprenticesList = ({ apprentices }) => {
  return (
    <>
      {apprentices.length > 0 ? (
        <DataTable
          fill
          sortable
          data={apprentices}
          columns={[
            {
              property: 'name',
              header: 'Name',
              primary: true,
            },
            {
              property: 'guid',
              header: 'guid',
            },
            {
              property :'group',
              header: 'Group',
            },
            {
              property: 'createdByUser.email',
              header: 'Created By',
            },
            ]}
          onClickRow={(e) => {
            window.location = "/apprentice/" + e.datum.guid;
          }}
        />
      ) : (
        <Text>Keine Einträge gefunden</Text>
      )}
    </>
  );
};
export default ApprenticesList;
