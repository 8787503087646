import {
    Text,
    Button,
    Card,
    CardBody,
    CardFooter,
    Layer,
} from "grommet";
import React from "react";
import { useParams } from "react-router-dom";

const PointForm = () => {
    //const [qrCode, setQrCode] = useState();
  const { guid, skill} = useParams();
  var givehref = "/apprentice/"+guid+"/givepointform/"+skill;
    return (
        
        <Layer>
            <Card round="small" width="medium" alignSelf="center" background="white">
                <CardBody pad="medium" justify="center">
                    <Text>Wollen sie Punkte Vergeben?</Text>
                </CardBody>
                <CardFooter direction="row" pad="small">
                    
                    <a href="/"><Button label="Später" /></a>
                    
                    <a href={givehref}><Button label="Ja" /></a>
                </CardFooter>
            </Card>
        </Layer>
    );
}
export default PointForm;