import {
  Box,
  Button,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Card
} from "grommet";
import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { ApprenticesApi, SkillsApi, TasksApi } from "../../../misc/Api";
import { Previous } from "grommet-icons";
import SkillsTable from "../../molecules/SkillsTable";
import PrintIcon from "../../atoms/PrintIcon";
import { Chip } from "../../atoms/Chip";
import * as Icons from "grommet-icons";

/**
 * This components renders all components for the OverviewPage and also fetches data
 * @returns 
 */

const OverviewPage = ({isPublic=false}) => {

  let { guid } = useParams();
  const [name, setName] = useState("");
  const [tasks, setTasks] = useState();
  const [skills, setSkills] = useState();
  
  useEffect(() => {
    ApprenticesApi.find({
      query: {
        guid: guid,
        $select: ["name"],
      },
    }).then((a) => setName(a.data[0].name));

    TasksApi.find({}).then((a) => {
      setTasks(a.data);
      
    });

    SkillsApi.find({
      query: {
        guid: guid,
      },
    }).then((a) => {
        setSkills(a.data);
    });
  }, []);

  const reduceSum = (accumulator, currentValue) => accumulator + currentValue;
  const givenPoints = () => skills.map(value => (parseInt(value.points) || 0)).reduce(reduceSum);
  const totalTasks = () => tasks.map(value => value.skills.length).reduce(reduceSum);
  const totalPoints = () => tasks.map(value => value.skills.map(value => parseInt(value.points) || 2).reduce(reduceSum)).reduce(reduceSum);
  const finalGrade = () => {
    return (givenPoints() / totalPoints())*5 + 1;
  }

  const GradeInfoAndPrint = () => {
    if(isPublic){return}
    if(!skills || !tasks){
      return <Text>Loading..</Text>
    }
    return (<>
    <Card width="225px" pad="small" round="small">
      <CardBody>
      <Text weight="bold"  alignSelf="center">Erreichte Note:</Text>
      <Text alignSelf="center" weight="bold">{finalGrade().toFixed(2)}</Text>
      </CardBody>
      <CardFooter direction="column">
      <Box >
      <Text>Total Kompetenzen: {totalTasks()}</Text>
      <Text >Total Punkte: {totalPoints()}</Text>
      <Text >Erreichte Punkte:{givenPoints()}</Text></Box>
      </CardFooter>
      </Card>
      <PrintIcon />  
    </>)
  }

  const ScanButton = () => {
    if(isPublic){return}
    return <Button primary label="Scan" icon={<Icons.Scan />} href="/scanner" margin={{ vertical: 'small', left: 'auto' }} />
  }

  return (
    <Box pad="xlarge">
      <Box direction="row" style={{ marginBottom: 10 }}>
        <Button icon={<Previous />} onClick={() => window.location = "/"} />
        <Heading margin="xxsmall">Übersicht von {name}</Heading>
        <Chip round="large">{guid}</Chip>
        {ScanButton()}
      </Box>
      <SkillsTable skills={skills} tasks={tasks} isPublic={isPublic} />
      {GradeInfoAndPrint()}
      <Outlet/>
    </Box>
  );
};
export default OverviewPage;
