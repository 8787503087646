import { Box, Heading } from "grommet";
import React from "react";
import { Outlet } from "react-router-dom";
import AuthGuard from "../../atoms/AuthGuard";
import UserList from "../../molecules/UserList";

/**
 * This component  renders all components for the HomePage
 * @returns 
 */
const HomePage = () => {
  return (
    <Box pad="xlarge">
      <AuthGuard />
      <Heading>Prüfungsübersicht</Heading>
      <UserList/>
      <Outlet/>
    </Box>
  );
};

export default HomePage;
