import { Box, Button, Spinner } from "grommet";
import React, { useEffect, useState } from "react";
import { ApprenticesApi } from "../../misc/Api";
import ApprenticesList from "../atoms/List";
import { Scan } from "grommet-icons";
/**
 * This component will render the list and the scanner and the corresponding buttons. It also handles some logic
 * @returns 
 */
const UserList = () => {
  const [apprentices, setApprentices] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    ApprenticesApi.find({
      query: {
        $sort: {
          createdAt: -1
        }
      },
    }).then((data) => {
      setApprentices(data.data);
    });
  }, []);

  return (
    <Box fill>
      <Button primary label="Scan" icon={<Scan/>} href="/scanner" margin={{vertical: 'small'}}/>
      <Box style={{ display: visible ? "none" : "block" }}>
        {apprentices ? (
          <ApprenticesList apprentices={apprentices} />
        ) : (
          <Spinner size="medium"></Spinner>
        )}
      </Box>
    </Box>
  );
};

export default UserList;
