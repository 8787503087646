import { ApprenticesApi, SkillsApi } from "./Api";

/**
 * Parses and saves the data received by the scanner
 * @param {*} data 
 */
export const parseAndSaveData = async (data) => {
    const parsed = parse(data);
    console.log(parsed);

    let result = await ApprenticesApi.find({
        query: {
            guid: parsed.guid
        }
    });

    if (result.total === 0) await ApprenticesApi.create(
        {
            guid: parsed.guid,
            name: parsed.name,
            group: parsed.group
        }
    );

    result = await SkillsApi.find({
        query: {
            guid: parsed.guid,
            skill: parsed.skill
        }
    });

    if (result.total === 0) await SkillsApi.create(
        {
            examnr: parsed.examnr,
            name: parsed.name,
            group: parsed.group,
            guid: parsed.guid,
            skill: parsed.skill,
            data: parsed.data,
            time: Date.now()
        }
    );
}

export const parse = (data) => {
    data = data.split("#");
    return {
        version: data[0],
        examnr: data[1],
        guid: data[2],
        name: data[3],
        skill: data[4],
        data: data[5],
        group: data[6],
    }
}

