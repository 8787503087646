import {
  Box,
  Button,
  Card,
  CardBody,
  Form,
  FormField,
  Heading,
  TextInput,
} from "grommet";
import React, { useState } from "react";
import { isAuthenticated, login } from "../../misc/Authentication";

/**
 * This component will render the login form
 * @returns
 */
const LoginForm = () => {
  const [value, setValue] = useState({});
  isAuthenticated().then((v) => (v ? (window.location = "/") : null));
  return (
    <Box>
      <Card alignSelf="center" align="center" margin="medium" pad="large">
        <CardBody>
          <Heading alignSelf="center" margin="xxsmall">
            James
          </Heading>
          <Heading level={2} alignSelf="center">
            Anmeldung
          </Heading>
          <Form
            value={value}
            onChange={(nextValue) => {
              setValue(nextValue);
            }}
            onReset={() => setValue({})}
            onSubmit={({ value }) => login(value)}
          >
            <FormField
              name="email"
              label="E-Mail"
              htmlFor="email-input-id"
              required
            >
              <TextInput
                name="email"
                id="email-input-id"
                type="email"
              ></TextInput>
            </FormField>
            <FormField
            required
              name="password"
              label="Passwort"
              htmlFor="password-input-id"
            >
              <TextInput
                type="password"
                id="password-input-id"
                name="password"
              ></TextInput>
            </FormField>
            <Box direction="row" gap="medium">
              <Button type="submit" primary label="Anmelden"></Button>
              <Button
                type="button"
                label="Registrieren"
                onClick={(event) => (window.location = "/register")}
              ></Button>
            </Box>
          </Form>
        </CardBody>
      </Card>
    </Box>
  );
};
export default LoginForm;
